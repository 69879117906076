<template>
    <div id="user_view" style="width:100%" class="mt-3">

        <v-tabs v-model="tabs" centered>
            <v-tab>Details</v-tab>
            <v-tab>Roles</v-tab>
            <v-tab>Metadata</v-tab>
        </v-tabs>

        <v-divider></v-divider>
        <v-tabs-items v-model="tabs" class="pa-2">

            <!--Details tab-->
            <v-tab-item class="mt-4">
                <v-text-field label="*Email" readonly dense maxlength="100"
                              placeholder="JohnDoe@reno.gov"
                              required
                              v-model="active_user_record.email"
                              :rules="[field_rules.required, field_rules.email]">
                </v-text-field>

                <v-text-field label="*First Name" dense maxlength="60"
                              placeholder="John"
                              required
                              v-model="active_user_record.fname"
                              :rules="[() => !!active_user_record.fname || 'This field is required']">
                </v-text-field>
                <v-text-field label="*Last Name" dense maxlength="60"
                              placeholder="Doe"
                              required
                              v-model="active_user_record.lname"
                              :rules="[() => !!active_user_record.lname || 'This field is required']">
                </v-text-field>
                <v-text-field label="Badge/Employee #" dense maxlength="10"
                              placeholder="12345"
                              required
                              v-model="active_user_record.badge"
                              :rules="[() => !!active_user_record.badge || 'This field is required']">
                </v-text-field>

                <div v-if="!active_user_record.notsworn" class="mb-4">
                    <v-icon color="primary">$info</v-icon>
                    Sworn RPD Officer
                </div>

                <div v-if="active_user_record.notsworn" class="mb-4">
                    <v-icon color="orange">$info</v-icon>
                    Not a Sworn RPD Officer
                </div>

                <div v-if="active_user_record.notsworn" class="ml-5">

                    <v-text-field label="Agency" dense maxlength="150"
                                  placeholder="Sparks Police Department"
                                  v-model="active_user_record.organization">
                    </v-text-field>

                    <v-text-field label="Division" dense maxlength="150"
                                  placeholder="Records"
                                  v-model="active_user_record.division">
                    </v-text-field>

                    <v-text-field label="Your Supervisor" dense maxlength="150"
                                  placeholder="Sgt. Doe"
                                  v-model="active_user_record.supervisor">
                    </v-text-field>

                    <v-text-field label="Contact Phone Number" dense @input="formatPhoneNumber" maxlength="14"
                                  placeholder="(775) 765-9895"
                                  v-model="active_user_record.contact_phone_number">
                    </v-text-field>

                </div>



            </v-tab-item>

            <!--Approles tab-->
            <v-tab-item class="mt-4 app-role-list" eager>
                <approle v-for="(app, index) in active_user_record.apps" :key="index" :app="app" @apply_defaultroles="apply_defaultroles">

                </approle>
            </v-tab-item>

            <!--Metadata tab-->
            <v-tab-item class="mt-4" eager>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>RPD User ID</v-list-item-title>
                        <v-list-item-subtitle>{{active_user_record.userID}}</v-list-item-subtitle>
                        <v-divider></v-divider>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Registration Submitted</v-list-item-title>
                        <v-list-item-subtitle>{{FormatDate(active_user_record.reg_sub)}}</v-list-item-subtitle>
                        <v-divider></v-divider>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Registration Approved</v-list-item-title>
                        <v-list-item-subtitle>{{FormatDate(active_user_record.reg_appr)}}</v-list-item-subtitle>
                        <v-divider></v-divider>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Last Logged In</v-list-item-title>
                        <v-list-item-subtitle>{{FormatDate(active_user_record.last_log)}}</v-list-item-subtitle>
                        <v-divider></v-divider>
                    </v-list-item-content>
                </v-list-item>


            </v-tab-item>
        </v-tabs-items>




        <v-divider class="mt-3"></v-divider>
        <div class="text-start" v-if="active_user_record.pending">
            <v-btn color="primary" outlined class="pa-2 ma-2" @click="approveReg">Approve</v-btn>
            <v-btn color="red" outlined class="pa-2 ma-2" @click="rejectReg">Reject</v-btn>
           
        </div>

        <div class="text-start" v-else>
            <v-btn color="primary" outlined class="pa-2 ma-2" @click="saveUser">Save</v-btn>
            <v-btn color="red" outlined class="pa-2 ma-2" @click="removeUser">Remove</v-btn>
            <v-btn color="primary" outlined class="pa-2 ma-2" @click="sendSupport">Send Support Email</v-btn>
        </div>



    </div>
</template>


<style lang="scss" scoped>
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import approle from "./app-role";
    export default {
        name: "user-view",
        mixins: [bus_common],
        props: ["active_user_record"],
        components: { approle },
        data() {
            return {
                tabs: "",
                field_rules: {
                    required: value => !!value || 'This field is required',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    uppercase: value => {
                        const pattern = /.*[A-Z].*/
                        return pattern.test(value) || '1 uppercase letter required'
                    },
                    lowercase: value => {
                        const pattern = /.*[a-z].*/
                        return pattern.test(value) || '1 lowercase letter required'
                    },
                    special: value => {
                        const pattern = /.*[!@#$&*%^].*/
                        return pattern.test(value) || '1 special (!@#$&*%^) character required'
                    },
                    number: value => {
                        const pattern = /.*[0-9].*/
                        return pattern.test(value) || '1 number required'
                    },
                    email: value => {
                        const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        return pattern.test(value) || 'Please provide a valid email address'
                    }
                },
                user_email: this.active_user_record.email
            };
        },
        methods: {
            clicked: function () {
                this.$emit("clicked", this.fabtype);
            },
            formatPhoneNumber() {
                var x = this.active_user_record.contact_phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.active_user_record.contact_phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

            },
            FormatDate: function (date) {
                let temp_date = this.$moment(date).format("L h:mm:ss A");

                if (temp_date == "01/01/1900 12:00:00 AM")
                    return "No valid date available"
                else
                    return temp_date;
            },
            approveReg: function () {
                let callback = () => { this.$emit("approveReg"); }
                this.swall_diag("Approve Registration?", "Are you sure you would like to approve the registration for " + this.user_email + "?", "question", callback, "Yes", "No", null)

            },
            rejectReg: function () {
                let callback = () => { this.$emit("rejectReg"); }
                this.swall_diag("Reject Registration?", "Are you sure you would like to reject the registration for " + this.user_email + "?", "question", callback, "Yes", "No", null)
            },
            saveUser: function () {
                this.$emit("saveUser");

            },
            removeUser: function () {
                let callback = () => { this.$emit("removeUser"); }
                this.swall_diag("Remove User?", "Are you sure you would like to remove " + this.user_email + "?", "question", callback, "Yes", "No", null)
            },
            sendSupport: function () {
                let callback = () => { this.$emit("sendSupport"); }
                this.swall_diag("Send support email?", "Are you sure you would like to send a support email to " + this.user_email + "?", "question", callback, "Yes", "No", null)
            },
            apply_defaultroles: function (applicationId) {
                this.$emit("apply_defaultroles", applicationId);
            }

        },

    };
</script>
