<template>
    <div id="app-role">      
        <v-list dense style="padding:5px;">
          
            <v-list-group :value="false" no-action eager>
                <template v-slot:prependIcon>
                    <v-icon color="green" v-if="app_status == `all`">$successBold</v-icon>
                    <v-icon color="blue" v-if="app_status == `some`">$successOutline</v-icon>
                    <v-icon color="grey" v-if="app_status == `none`">$successOutline</v-icon>

                </template>
                <template v-slot:activator>

                    <v-list-item-title v-if="app.requested" style="color:red" class="text-uppercase">
                        {{app.appname}}
                    </v-list-item-title>

                    <v-list-item-title v-else class="text-uppercase">
                        {{app.appname}}
                    </v-list-item-title>
                </template>
               
                <v-btn class="ml-15" text small color="primary" @click="apply_defaultroles">
                    <v-icon class="mr-1">$databasedown</v-icon> Defaults
                </v-btn>
                
                <v-list-item v-for="(role,index) in app.user_roles" :key="index" dense>
                    <v-list-item-action>
                        <v-checkbox dense v-model="role.status" :label="role.roledisplay" class="text-capitalize"></v-checkbox>
                    </v-list-item-action>




                </v-list-item>

            </v-list-group>
            <v-divider></v-divider>
        </v-list>
    </div>
</template>


<style lang="scss" scoped>


   </style>

<script>
    /* eslint-disable */
    export default {
        name: "app-role",
        props: ["app"],
        data() {
            return {
                open: false,
            };
        },
        methods: {
            clicked: function () {
                this.$emit("clicked", this.fabtype);
            },
            apply_defaultroles: function () {
                let applicationId = this.app.applicationId;
                this.$emit("apply_defaultroles", applicationId);
            }
        },
        computed: {
            app_status: function () {

                //Count how many roles are set to true
                //If less then max -> itermidate
                //If max all set -> green
                //If 0, -> none
                let status_count = 0;
                for (var i = 0; i < this.app.user_roles.length; i++) {

                    if (this.app.user_roles[i].status) {
                        status_count++;
                    }
                }

                if (status_count < this.app.user_roles.length && status_count > 0) {
                    return "some"
                }
                else if (status_count == this.app.user_roles.length) {
                    return "all"
                }
                else {
                    return "none"
                }

            }
        }
    };
</script>
