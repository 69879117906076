<template>
    <Transition name="fade-transition">
        <div id="adminash">

            <v-card class="admin-card">

                <!--Admin dash Header-->
                <v-card-title class="secondary white--text text-h6">

                    <v-text-field v-model="search_users" label="Search RPD Users" flat solo hide-details clearable
                                  prepend-inner-icon="$textSearchOutline" append-icon="$send" @keydown.enter="SearchUsers"
                                  @click:prepend-inner="filterSearch" @click:append="SearchUsers" @click:clear="resetSearch">
                    </v-text-field>

                </v-card-title>

                <!--Admin dash body-->
                <v-row class="ma-1 admin-body" justify="space-between" style="">

                    <!--Left Panel-->
                    <v-col cols="12" md="3" class="pa-1 mb-lg-5">
                        <v-expansion-panels accordion v-model="user_panel" color="secondary" flat class="coll-panels">
                            <v-expansion-panel>
                                <v-expansion-panel-header class="text-h7 font-weight-bold" style="min-height:54px; padding:15px;">
                                    RPD Users ({{users.length}})

                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="user_coll overflow-y-auto">
                                    <v-divider class="mb-3"> </v-divider>
                                    <div v-if="filter_text.length > 0">
                                        <div class="text-caption font-weight-medium ml-2">Filter(s) Applied :</div>
                                        <ul class="mb-2 text-caption font-weight-medium">
                                            <li v-for="(text,index) in filter_text" :key="index">{{text}}</li>
                                        </ul>
                                        <v-divider class="mb-3"> </v-divider>
                                    </div>

                                    <v-data-iterator :loading="true" :loading-text="loading_text" :items="users" :search="search_users" hide-default-footer disable-filtering disable-pagination class="user_list">

                                        <template v-slot:loading>

                                            <v-row no-gutters>
                                                <v-col cols="12" v-for="index in 12" :key="index" class="">


                                                    <v-skeleton-loader class=" avatar-loader" type="list-item-avatar-two-line">
                                                    </v-skeleton-loader>


                                                </v-col>

                                            </v-row>

                                        </template>



                                        <template v-slot:default="props">
                                            <v-btn-toggle v-model="user_toggle" class="user-toggle-list" dense>
                                                <v-row no-gutters v-for="(item,index) in props.items" :key="index">

                                                    <!-- Exisiting user button-->
                                                    <v-btn outlined width="100%" class="pa-0 mb-1 user-btn" style="border-color: black !important;" :value="item.userID" v-if="!item.pending">
                                                        <v-row no-gutters align="start" align-content="start" justify="start" class="text-start">
                                                            <v-col cols="2" class="text-start">
                                                                <v-icon>$account</v-icon>
                                                            </v-col>

                                                            <v-col cols="10" class="text-start">
                                                                <strong class="d-inline-block text-truncate" style="max-width: 175px;">{{item.lname}}, {{ item.fname }} </strong>

                                                                <div class="font-italic text-truncate" style="font-size: x-small; max-width: 175px;">
                                                                    {{ item.email }}
                                                                </div>
                                                            </v-col>

                                                        </v-row>
                                                    </v-btn>

                                                    <!-- Pending user button-->
                                                    <v-btn outlined width="100%" class="pa-0 mb-1 user-btn" style="border-color: black !important; background-color: #FFFF8D;" :value="item.userID" v-else>
                                                        <v-row no-gutters align="start" align-content="start" justify="start" class="text-start">
                                                            <v-col cols="2" class="text-start">
                                                                <v-icon>$helpCircle</v-icon>
                                                            </v-col>

                                                            <v-col cols="10" class="text-start">
                                                                <strong class="d-inline-block text-truncate" style="max-width: 175px;">{{item.lname}}, {{ item.fname }} </strong>

                                                                <div class="font-italic text-truncate" style="font-size: x-small; max-width: 175px;">
                                                                    {{ item.email }}
                                                                </div>
                                                            </v-col>

                                                        </v-row>
                                                    </v-btn>


                                                </v-row>
                                            </v-btn-toggle>
                                        </template>


                                    </v-data-iterator>

                                    <v-row dense align="center" justify="center" >
                                        <v-btn color="black" outlined :disabled="users.length == 0" class="ma-2" fab @click="downloadUsersCSV">
                                            <v-icon dark>
                                                $download
                                            </v-icon>
                                        </v-btn>                                      
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>

                    </v-col>

                    <!--Right panel-->
                    <v-col cols="12" md="9" class="d-flex overflow-y-auto user_view mb-8" style="padding-top:0px">

                        <userview v-if="active_user_record" :active_user_record="active_user_record" 
                                  @approveReg="approveReg" @rejectReg="rejectReg" @saveUser="saveUser" @removeUser="removeUser" @sendSupport="sendSupport"
                                  @apply_defaultroles="apply_defaultroles"> 
                        </userview>

                        <div v-else class="text-h6 text--lighten-1 font-weight-light mx-auto mt-2" style="align-self: center;">
                            Please select a user to open
                        </div>

                    </v-col>

                </v-row>
            </v-card>


            <filterdialog v-show="filter_dialog" :filter_form="filter_form" @resetFilter="resetSearch" @closeDialog="close_filter_dialog" @applySearchFilter="applySearchFilter" :dialog="filter_dialog" @filterTextUpdated="filter_text_update">
            </filterdialog>

            <v-overlay absolute :value="overlay_loading">
                <v-progress-circular indeterminate :size="80" :width="7"
                                     color="primary"></v-progress-circular>
            </v-overlay>

        </div>
    </Transition>
</template>


<style lang="scss" scoped>

    .avatar-loader {
        padding: 0px !important;
        border: 0.5px solid grey;
        margin-bottom: 5px;
    }



    .user_coll {
        max-height: 55vh;
    }

    .user-toggle-list {
        flex-direction: column;
        display: block;
    }

    .user-btn {
        height: 36px !important;
        border: 1px solid black !important;
        border-color: black !important;
    }

    .user_list {
        padding: 10px;
        padding-top: 0px;
    }

    .app-role-list {
        max-height: 45vh;
        overflow-y: auto;
    }

    .user_view {
        max-height: 65vh;
    }

    .coll-panels {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
        padding: 10px !important;
    }

    .v-expansion-panel-header {
        padding: 20px;
    }

    @media only screen and (max-width: 600px) {
        .user_coll {
            max-height: 30vh;
        }

        .user_view {
            max-height: none !important;
        }

        .app-role-list {
            max-height: none !important;
            overflow-y: hidden !important;
        }

        .admin-card {
            margin-bottom: 50px;
        }
    }
</style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import userview from "./userview";
    const filterdialog = () => import('./filterdialog')
    import Papa from "papaparse";
    export default {
        name: "admindash",
        mixins: [bus_common],
        components: { userview, filterdialog },
        data() {
            return {
                default_approles: [],
                filter_dialog: false,
                user_toggle: null,
                user_panel: 0,
                isMobile: this.$vuetify.breakpoint.mobile,
                search_users: "",
                field_rules: {
                    required: value => !!value || 'This field is required',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    match: v => v == this.reg_form.password || 'The passwords you entered do not match',
                    uppercase: value => {
                        const pattern = /.*[A-Z].*/
                        return pattern.test(value) || '1 uppercase letter required'
                    },
                    lowercase: value => {
                        const pattern = /.*[a-z].*/
                        return pattern.test(value) || '1 lowercase letter required'
                    },
                    special: value => {
                        const pattern = /.*[!@#$&*%^].*/
                        return pattern.test(value) || '1 special (!@#$&*%^) character required'
                    },
                    number: value => {
                        const pattern = /.*[0-9].*/
                        return pattern.test(value) || '1 number required'
                    },
                    email: value => {
                        const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        return pattern.test(value) || 'Please provide a valid email address'
                    }
                },
                users: [




                ],
                active_user_record: null,
                loading_init: false,
                overlay_loading: false,
                filter_form: {
                    last_login_sdate: "",
                    last_login_edate: "",
                    approved_sdate: "",
                    approved_edate: "",
                    user_id: 0,
                    submitted_sdate: "",
                    submitted_edate: "",
                },
                filter_text: [],
                loading_text: "Retrieving RPD Users ... ",
                no_results_text: "No matching RPD Users were found.",
                /*loginURL: 'https://localhost:44365/'*/
                loginURL: this.$store.state.appURL
            };
        },
        methods: {
            filterSearch() {
                this.open_filter_dialog();
            },
            open_filter_dialog() {
                this.filter_dialog = true;
            },
            close_filter_dialog() {
                this.filter_dialog = false;
            },
            applySearchFilter() {
                this.close_filter_dialog();
                this.SearchUsers();

            },
            resetSearch() {
                this.filter_form = {
                    last_login_sdate: "",
                    last_login_edate: "",
                    approved_sdate: "",
                    approved_edate: "",
                    user_id: 0,
                    submitted_sdate: "",
                    submitted_edate: "",
                };
                this.search_users = "";
                this.init();
                this.close_filter_dialog();
            },
            async SearchUsers() {

                //Copy current users
                let usersCopy = JSON.parse(JSON.stringify(this.users));
                this.users = [];
                //Copy search form
                let searchForm = JSON.parse(JSON.stringify(this.filter_form));
                searchForm.searchTerm = this.search_users;
                //Fill in any empty dates in search form to prevent .Net errors
                if (searchForm.last_login_sdate == "")
                    searchForm.last_login_sdate = '1900-01-01'
                if (searchForm.last_login_edate == "")
                    searchForm.last_login_edate = '1900-01-01'

                if (searchForm.approved_sdate == "")
                    searchForm.approved_sdate = '1900-01-01'
                if (searchForm.approved_edate == "")
                    searchForm.approved_edate = '1900-01-01'

                if (searchForm.submitted_sdate == "")
                    searchForm.submitted_sdate = '1900-01-01'
                if (searchForm.submitted_edate == "")
                    searchForm.submitted_edate = '1900-01-01'

                searchForm.user_id = parseInt(searchForm.user_id);


                var form = {
                    token: this.$store.state.user.token,
                    searchForm: searchForm,
                };

                await this.$axios.post('api/admin/searchUsers', form)
                    .then(response => {

                        this.users = response.data;
                        if (this.users.length == 0) {
                            this.users = usersCopy;
                            let msg = "No users were found using your search parameters. Please try again."
                            this.swall_it("Search!", msg, "warning", null, null);
                        }


                    }).catch(error => {
                        if (error.response) {
                            this.users = usersCopy;
                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }
                        }
                    });



            },
            filter_text_update(val) {
                this.filter_text = val
            },
            async checkAdminRole() {
                //Double check the user has the correct roles
                let callback = () => { window.location.replace(this.loginURL); }

                if (this.$store.state.user.roles.indexOf('rl-admin') == -1) {

                    this.$swal({
                        title: "Invalid Role!",
                        text: "You do not have the needed role for this app, you will be redirected to the login page.",
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#283593',
                        timer: 4000,
                        didClose: callback
                    });
                }
            },
            async init() {

                this.loading_init = true;
                let callback = () => { window.location.replace(this.loginURL); }

                await this.checkAdminRole();

                var form = { token: this.$store.state.user.token };
                await this.$axios.post('api/admin/init', form)
                    .then(response => {

                        this.users = response.data.users_list;
                    }).catch(error => {
                        if (error.response) {

                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }


                        }
                    });

                this.loading_init = false;
                this.init_defaultroles();

            },
            async get_user_record() {

                this.overlay_loading = true;

                const index = this.users.findIndex(item => item.userID === this.user_toggle);
                if (index != -1) {
                    let user_record = this.users[index];

                    var form = {
                        token: this.$store.state.user.token,
                        userID: user_record.userID,
                        pending: user_record.pending
                    };

                    await this.$axios.post('api/admin/userrecord', form)
                        .then(response => {
                            this.active_user_record = null;
                            this.$nextTick(() => {
                                this.active_user_record = response.data;
                            });

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                }

                else {
                    this.active_user_record = null;
                    /* this.swall_it("Error!", "An error occurred in retrieving the data for this user. Please try again.", "error", null, null);*/
                }

                this.overlay_loading = false;

            },
            async approveReg() {
                this.overlay_loading = true;

                const index = this.users.findIndex(item => item.userID === this.user_toggle);
                if (index != -1) {
                    // let user_record = this.users[index];

                    var form = {
                        token: this.$store.state.user.token,
                        user_record: JSON.parse(JSON.stringify(this.active_user_record))
                    };

                    await this.$axios.post('api/admin/approveReg', form)
                        .then(response => {

                            this.swall_it("Approved!", "The registration for this user has been successfully approved.", "success", null, null);
                            //CHANGE Refresh app data based on view/search?

                            this.init();

                            this.active_user_record = null;
                            this.user_toggle = null;
                            this.$nextTick(() => {
                                this.active_user_record = response.data;

                            });

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                }

                else {
                    this.active_user_record = null;
                    /* this.swall_it("Error!", "An error occurred in retrieving the data for this user. Please try again.", "error", null, null);*/
                }

                this.overlay_loading = false;
            },
            async rejectReg() {
                this.overlay_loading = true;

                const index = this.users.findIndex(item => item.userID === this.user_toggle);
                if (index != -1) {
                    // let user_record = this.users[index];

                    var form = {
                        token: this.$store.state.user.token,
                        userID: this.active_user_record.userID,
                        email: this.$store.state.user.email,
                    };

                    await this.$axios.post('api/admin/rejectReg', form)
                        .then(response => {

                            this.swall_it("Rejected!", response.data.msg, "success", null, null);

                            this.init();
                            this.active_user_record = null;
                            this.user_toggle = null;

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                }

                else {
                    this.active_user_record = null;
                    /* this.swall_it("Error!", "An error occurred in retrieving the data for this user. Please try again.", "error", null, null);*/
                }

                this.overlay_loading = false;
            },
            async saveUser() {
                this.overlay_loading = true;

                const index = this.users.findIndex(item => item.userID === this.user_toggle);
                if (index != -1) {
                    // let user_record = this.users[index];

                    var form = {
                        token: this.$store.state.user.token,
                        email: this.$store.state.user.email,
                        user_record: JSON.parse(JSON.stringify(this.active_user_record))
                    };

                    await this.$axios.post('api/admin/saveUser', form)
                        .then(response => {


                            if (!this.search_filled)
                                this.init();
                            else
                                this.SearchUsers();

                            this.active_user_record = null;
                            this.$nextTick(() => {
                                this.active_user_record = response.data;
                            });

                            this.swall_it("Saved!", "This user's data has been successfully saved.", "success", null, null);


                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                }

                else {
                    this.active_user_record = null;
                    /* this.swall_it("Error!", "An error occurred in retrieving the data for this user. Please try again.", "error", null, null);*/
                }

                this.overlay_loading = false;
            },
            async removeUser() {
                this.overlay_loading = true;

                const index = this.users.findIndex(item => item.userID === this.user_toggle);
                if (index != -1) {
                    // let user_record = this.users[index];

                    var form = {
                        token: this.$store.state.user.token,
                        userID: this.active_user_record.userID,
                        email: this.$store.state.user.email,
                    };

                    await this.$axios.post('api/admin/removeUser', form)
                        .then(response => {

                            this.swall_it("Removed!", response.data.msg, "success", null, null);

                            //Find user in list and remove by force
                            this.users.splice(index, 1)

                            if (!this.search_filled)
                                this.init();
                            else
                                this.SearchUsers();

                            this.active_user_record = null;
                            this.user_toggle = null;

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                }

                else {
                    this.active_user_record = null;
                    /* this.swall_it("Error!", "An error occurred in retrieving the data for this user. Please try again.", "error", null, null);*/
                }

                this.overlay_loading = false;
            },
            async sendSupport() {
                this.overlay_loading = true;

                const index = this.users.findIndex(item => item.userID === this.user_toggle);
                if (index != -1) {
                    // let user_record = this.users[index];

                    var form = {
                        token: this.$store.state.user.token,
                        userID: this.active_user_record.userID,
                        email: this.$store.state.user.email,
                    };

                    await this.$axios.post('api/admin/sendSupport', form)
                        .then(response => {

                            this.swall_it("Email Sent!", response.data.msg, "success", null, null);

                        }).catch(error => {
                            if (error.response) {
                                if (error.response.data.msg.includes("Your session will now refresh.")) {

                                    this.$swal({
                                        title: "Error!",
                                        text: error.response.data.msg,
                                        icon: "error",
                                        confirmButtonText: "OK",
                                        confirmButtonColor: '#283593',
                                        timer: 4000,
                                        didClose: callback
                                    });
                                }
                                else {
                                    this.swall_it("Error!", error.response.data.msg, "error", null, null);
                                }
                            }
                        });
                }

                else {
                    this.active_user_record = null;
                    /* this.swall_it("Error!", "An error occurred in retrieving the data for this user. Please try again.", "error", null, null);*/
                }

                this.overlay_loading = false;
            },
            async init_defaultroles() {
                var form = { token: this.$store.state.user.token };
                await this.$axios.post('api/roles/defaultInit', form)
                    .then(response => {
                        this.default_approles = response.data.apps;
                    }).catch(error => {
                        if (error.response) {

                            if (error.response.data.msg.includes("Your session will now refresh.")) {

                                this.$swal({
                                    title: "Error!",
                                    text: error.response.data.msg,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                            else {
                                this.swall_it("Error!", error.response.data.msg, "error", null, null);
                            }


                        }
                    });
            },
            get_defaultroles(applicationId) {
                //Helper func: Given the PK of the app, return app index within default_approles
                const app_index = this.default_approles.findIndex(item => item.applicationId == applicationId);
                if (app_index == -1) {
                    this.swall_it("Error!", "The default roles for this app could not be found. Please try again.", "error", null, null);
                    return -1;
                }
                else {
                    return app_index;
                }
            },
            lookup_defaultrolestatus(app_index, rlsID) {
                //Helper func: Given index of the defapp and role PK, return role status
                let status = false;
                const role_index = this.default_approles[app_index].default_roles.findIndex(item => item.rlsID == rlsID);
                if (role_index != -1) {
                    status = this.default_approles[app_index].default_roles[role_index].status;
                }
                return status;
            },
            apply_defaultroles(applicationId) {
                //Main func: Given the app PK, set the roles to default of current viewing record/app

                let defaultapp = this.get_defaultroles(applicationId);
                if (defaultapp != -1) {
                    const app_index = this.active_user_record.apps.findIndex(item => item.applicationId == applicationId);
                    if (app_index != -1) {

                        for (var i = 0; i < this.active_user_record.apps[app_index].user_roles.length; i++) {

                            var lookrlsId = this.active_user_record.apps[app_index].user_roles[i].rlsID;
                            var newstatus = this.lookup_defaultrolestatus(defaultapp, lookrlsId)
                            this.active_user_record.apps[app_index].user_roles[i].status = newstatus;

                        }
                    }
                }
            },
            downloadUsersCSV() {
                let UsersCopy = JSON.parse(JSON.stringify(this.users))
                var validKeys = ['fname', 'lname', 'email'];
                for (var i = 0; i < UsersCopy.length; i++) {
                    Object.keys(UsersCopy[i]).forEach((key) => validKeys.includes(key) || delete UsersCopy[i][key]);
                }
                var csv = Papa.unparse(UsersCopy);
                var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var csvURL = window.URL.createObjectURL(csvData);
                var testLink = document.createElement('a');
                testLink.href = csvURL;
                var dt = this.$moment(Date.now()).tz('America/Los_Angeles').format("YYYYMMDD-HHmm");
                testLink.download = 'UsersQuery_' + dt + '.csv';
                testLink.setAttribute('test', 'test.csv');
                testLink.click();
            }

        },
        computed: {
            search_filter() {
                return this.caseSensitive
                    ? (item, search, textKey) => item[textKey].indexOf(search) > -1
                    : undefined
            },
            search_filled() {
                return !!this.search_users || !!this.filter_form.last_login_sdate
                    || !!this.filter_form.last_login_edate || !!this.filter_form.approved_sdate
                    || !!this.filter_form.approved_edate || this.filter_form.user_id != 0
                    || !!this.filter_form.submitted_sdate || !!this.filter_form.submitted_edate
            },



        },
        watch: {

            '$store.state.user.token': function () {
                if (this.$store.state.user.token != "" && this.loading_init == false) {
                    this.init();
                }
            },

            user_toggle: function () {

                if (this.user_toggle !== 'undefined' || this.user_toggle !== null) {
                    this.get_user_record();
                }
                else {
                    this.active_user_record == null;
                }
            }

        },
        mounted() {



            if (this.$store.state.user.token != "" && this.loading_init == false) {
                this.init();
            }

        },
    };
</script>
